.contactWrap {
  background-color: #000;
  min-height: 87vh;
  color: #fff !important;
  padding: 20px;
}

.contact {
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  margin: 150px auto 100px;
}

.leftContact {
  width: 55%;
}

.contactIntro {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  max-width: 500px;
}

.contactIntro h3 {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
}

.leftContact p {
  font-size: 20px;
  margin: 25px 0;
  font-weight: 600;
}

.iconWrap {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.iconWrap img {
  border-radius: 15px;
  margin-right: 8px;
}

.icon {
  margin: 0 5px;
}

.rightContact {
  width: 45%;
  padding-left: 50px;
}

.contactForm {
  border: 5px solid #414141;
  padding: 20px;
  border-radius: 15px;
  margin-top: -20px;
  background-color: #606060;
}

.inputWrap {
  display: flex;
  flex-direction: column;
}

.inputWrap label {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.inputWrap input,
.inputWrap textarea {
  border: 3px solid #fff;
  border-radius: 15px;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 15px;
}

.error {
  font-size: 12px;
  margin: -12px 0 15px 15px;
}

@media (max-width: 768px) {
  .leftContact {
    width: 50%;
  }
  .rightContact {
    width: 50%;
  }
  .contactIntro h3 {
    font-size: 36px;
    bottom: -30px;
  }
}

@media (max-width: 650px) {
  .contact {
    flex-direction: column;
    margin: 0 auto 70px;
  }

  .leftContact {
    width: 90%;
    margin: 0 auto;
    max-width: 350px;
  }

  .contactIntro h3 {
    font-size: 36px;
    bottom: -20px;
  }

  .rightContact {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    max-width: 400px;
  }

  .contactForm {
    margin-top: 30px;
  }
}

@media (max-width: 400px) {
  .contact {
    margin: 0 auto 50px;
  }

  .contactIntro h3 {
    bottom: -10px;
  }
}

@media (max-width: 355px) {
  .contactIntro h3 {
    font-size: 22px;
  }
}

@media (max-width: 280px) {
  .contactIntro h3 {
    font-size: 18px;
    bottom: 0;
  }
}
