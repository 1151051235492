/* Modal.module.css */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to ensure the backdrop is above other content */
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: 500px;
  z-index: 1001; /* Higher than backdrop to appear on top */
}
