.homeWrap {
  background-color: #000;
  color: #fff;
}

.backgroundWrap {
  padding: 20px;
  background-image: url("../../../../public/background2.png"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 100%;
  max-width: 1700px;
  margin: 0 auto;
  box-shadow: inset 0 10px 40px 50px rgba(0, 0, 0, 1);
}

.heroWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}

.subHeadline {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.headline {
  font-size: 60px;
  font-weight: bold;
  margin: 12px 0;
  max-width: 50%;
  text-align: center;
  max-width: 620px;
}

.pricingSubHeadline {
  font-size: 20px;
  font-weight: 600;
  color: #fbb144;
  max-width: 300px;
  text-align: center;
  margin: 12px;
}

.aboutSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 50px;
}

.featureSection {
  margin-top: 50px;
}

.topImage1 {
  width: 100%;
  height: 100px;
  background-image: url("../../../../public/top1.png");
  background-size: cover;
  background-position: center;
}

.bottomImage1 {
  width: 100%;
  height: 100px;
  background-image: url("../../../../public/bottom1.png");
  background-size: cover;
  background-position: center;
}

.featuresWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 60px 0;
  background-color: #111c11;
}

.featuresHeader {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* This will space your items evenly */
  gap: 20px;
  padding: 40px;
  width: 80%;
  margin: 30px auto 0;
}

.modShowcaseSection {
  margin-top: 50px;
  margin-bottom: 50px;
}

.modShowcaseHeader {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  color: #e8e8e8;
  font-style: italic;
  text-align: center;
}

.sliderContainer {
  width: 1000px;
  margin: 40px auto 0;
}

.featureSection {
  margin-top: 50px;
}

.topImage2 {
  width: 100%;
  height: 100px;
  background-image: url("../../../../public/top2.png");
  background-size: cover;
  background-position: center;
}

.bottomImage2 {
  width: 100%;
  height: 100px;
  background-image: url("../../../../public/bottom2.png");
  background-size: cover;
  background-position: center;
}

.calculatorWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 60px 0;
  background-color: #313131;
}

.calculatorHeader {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  color: #cecece;
  text-align: center;
}

.callToAction {
  display: flex;
  justify-content: center;
  align-items: center;
}

.callToActionWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 60px 0;
}

.callToActionHeader {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.callToActionDesc {
  font-size: 24px;
  font-weight: 600;
  max-width: 50%;
  text-align: center;
  margin: 20px 0 40px;
}

@media screen and (max-width: 1200px) {
  .aboutSection {
    flex-direction: column;
    align-items: center;
  }
  .features {
    width: 100%;
  }
  .featuresHeader {
    font-size: 24px;
  }
  .featuresWrap {
    padding: 40px 20px;
  }
  .modShowcaseHeader {
    font-size: 28px;
  }
  .sliderContainer {
    width: 700px;
  }
  .calculatorHeader {
    font-size: 28px;
  }
  .callToActionHeader {
    font-size: 40px;
  }
  .callToActionDesc {
    max-width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .aboutSection {
    flex-direction: column;
    align-items: center;
  }

  .headline {
    max-width: 100%;
  }
  .subHeadline {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  .features {
    width: 100%;
    padding: 20px;
  }
  .featuresWrap {
    padding: 40px 20px;
  }
  .callToActionDesc {
    max-width: 80%;
  }
  .sliderContainer {
    width: 300px;
  }
  .modShowcaseSection {
    margin: 20px;
  }
  .calculatorWrap {
    padding: 40px 20px;
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .aboutSection {
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .features {
    width: 100%;
  }
  .featuresWrap {
    padding: 40px 20px;
  }
  .callToActionDesc {
    max-width: 80%;
  }
}
