.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  height: 100vh;
  padding: 16px;
  width: 260px;
}

.collapsed {
  width: auto;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.logo p {
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.49);
  font-style: normal;
  margin: 0;
  font-weight: 700;
  line-height: normal;
  margin-right: 8px;
}

.logo svg {
  transform: rotate(180deg);
}

.logo svg path {
  stroke: #ccc;
}

.collapsed .logo svg {
  transform: rotate(0deg);
  margin-left: -3px;
}

.navigation {
  flex-grow: 1;
}

.navItem {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  padding: 9px 0 9px 10px;
  cursor: pointer;
  transition: color 0.3s;
  width: 100%;
}

.navItem:hover {
  background-color: #222; /* Darker background on hover for interactivity */
}

.navItem svg {
  width: 28px;
  height: 28px;
  margin-right: 15px;
}

.navItem span {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.navItemName {
  flex-grow: 1;
  text-align: left;
}

.navItem:hover,
.navItem:focus {
  color: #ffa500; /* This is the hover state, using a generic orange. Adjust as needed */
}

.externalLink svg {
  width: 20px;
  height: 20px;
  flex-grow: 1;
}

.selected span,
.selected svg g path {
  fill: #e8b974;
  color: #e8b974; /* Selected state color */
}

.icon {
  margin-right: 10px;
  /* You can set a fill here if your SVG allows it */
}

.footer {
  padding-top: 5px;
  border-top: 1px solid #444; /* A lighter line for separation */
  text-align: center;
  position: relative;
  outline: none;
}

.chevron {
  /* Style your chevron icon */
  transition: transform 0.3s; /* Smooth rotation animation */
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.userInfoContent {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.userInfo:hover {
  background-color: #222; /* Darker background on hover for interactivity */
}

.userInfoContentCredits {
  font-weight: 600;
  font-size: 14px;
  color: #bcbcbc;
  text-align: left;
}

.rotate {
  transform: rotate(180deg); /* Rotates the chevron when the menu is open */
}

.userMenu {
  position: absolute;
  bottom: 100%; /* Position above the userInfo */
  left: 0;
  right: 0;
  background: #333; /* Match the sidebar color */
  z-index: 10; /* Ensure it stacks on top */
}

.menuItem {
  width: 100%;
  padding: 15px 20px;
  text-align: left;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.collapsed .userMenu {
  width: 110px;
  background-color: #444;
}

.menuItem:hover {
  background-color: #444; /* Slightly lighter than the sidebar for hover effect */
}
