.button {
  padding: 18px 20px;
  margin-top: 20px;
  border: none;
  color: #131313;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  display: block;
  text-decoration: none;
  border-radius: 15px;
  font-weight: 700;
  box-shadow: none;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
}

.primary {
  background: #a2e461;
}

.secondary {
  background: transparent;
  color: white;
  border: 3px solid #a2e461;
}

.primary:hover {
  background: darken(#a2e461, 10%);
}

.secondary:hover {
  background: #a2e461;
  color: #131313;
}
