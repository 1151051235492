.app {
  display: flex;
  height: 100vh;
}

.main {
  display: flex;
  flex: 1;
  overflow: hidden;
}
