.listItem {
  margin-bottom: 20px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title p {
  margin: 0;
  font-weight: bold;
  color: #345333;
  font-size: 18px;
  margin-right: 4px;
}

.titleAdd {
  cursor: pointer;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9e9e9;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 10px;
}

.user p {
  margin: 0;
  color: #777;
  font-weight: 500;
  font-size: 11px;
}

.user h6 {
  margin: 0;
  color: #404040;
  font-weight: bold;
  font-size: 16px;
}

.extraField {
  text-transform: capitalize;
}

.userDelete {
  cursor: pointer;
}
