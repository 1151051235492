.footer {
  background-image: url("../../../public/footer.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px 50px 10px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  box-shadow: inset 0px 15px 20px 10px rgba(0, 0, 0, 0.8);
  align-items: center;
  height: 130px;
}

.footer > div {
  width: 33%;
}

.leftWrap {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
}

.leftWrap h6 {
  margin: 0;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.leftWrap h6:hover {
  transform: scale(1.03);
}

.leftWrap p {
  margin: 0 10px;
}

.centerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centerWrap h6 {
  font-size: 22px;
  margin: 0;
  color: #969696;
  text-align: center;
}

.rightWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.iconWrap img {
  border-radius: 12px;
  margin-right: 8px;
}

.icon {
  margin: 0 5px;
}

@media (max-width: 900px) {
  .centerWrap h6 {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    height: auto;
  }

  .footer > div {
    width: 100%;
    margin-top: 10px;
  }

  .leftWrap,
  .centerWrap,
  .rightWrap {
    justify-content: center;
  }

  .iconWrap img {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .centerWrap h6 {
    font-size: 18px;
  }

  .rightWrap img {
    font-size: 12px;
    border-radius: 9px;
    width: 30px;
    height: 30px;
  }
}
