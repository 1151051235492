.calculatorWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  margin: 50px 0 40px;
}

.sliderContainer {
  width: 100%;
  margin-right: 20px;
  background-color: #9a9a9a;
  padding: 20px 30px;
  border-radius: 30px;
  border: 6px solid #414141;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sliderRamWrap {
  margin: 0;
  margin-bottom: 30px;
}

.sliderRunTimeWrap {
  margin: 0;
}

.sliderRunTimeHeader,
.sliderRamHeader {
  display: flex;
  justify-content: space-between;
}

.sliderContainer label,
.sliderContainer output {
  display: block;
  color: #fff;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.sliderContainer output {
  font-size: 20px;
  font-weight: 600;
  align-self: center;
}

.sliderContainer input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 10px 0;
  height: 10px;
}

.sliderContainer input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px; /* Width of the handle */
  height: 25px; /* Height of the handle */
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.sliderContainer input[type="range"]::-moz-range-thumb {
  width: 25px; /* Width of the handle */
  height: 25px; /* Height of the handle */
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular handle */
}

.estimatedTotalWrap {
  min-width: 270px;
  max-width: 270px;
  background-color: #9a9a9a;
  padding: 20px;
  border-radius: 30px;
  border: 6px solid #414141;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.totalPriceHeader {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;
}

.totalPrice,
.totalPriceAmount {
  font-weight: bold;
  color: #42681c;
  font-size: 30px;
  margin: 0;
  margin-top: -8px;
}

.totalPriceAmount {
  color: #8cc057;
  font-size: 45px;
}

@media screen and (max-width: 700px) {
  .calculatorWrap {
    flex-direction: column;
    align-items: center;
    padding: 50px;
    margin-top: 0;
  }

  .sliderContainer {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .estimatedTotalWrap {
    min-width: 100%;
    max-width: 100%;
  }
}
