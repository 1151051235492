.authContainer {
  display: flex;
  background-color: #000;
}

.authBackgroundWrap {
  background-image: url("/public/signin.png");
  display: flex;
  height: 100vh;
  padding: 20px;
  background-size: cover;
  background-position: center;
  flex-grow: 1;
}

.formWrapper {
  background: rgba(255, 255, 255, 1);
  padding: 30px 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
}

.logo {
  color: #606060;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 80px;
  cursor: pointer;
}

.title {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitleWrap {
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
}

.subtitleWrap p {
  margin: 0;
  margin-right: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitleWrap a {
  color: #000;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #9fcb74;
  font-weight: 700;
  text-decoration-line: underline;
}

.inputWrap {
  margin-bottom: 40px;
}

.forgotPasswordWrap {
  display: flex;
  font-size: 14px;
  justify-content: end;
}

.forgotPassword {
  color: #606060;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-align: right;
  margin-right: 5px;
}

.link:hover {
  text-decoration: underline;
}

.authError {
  color: #ff0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 30px 0 0;
  text-align: center;
}
