/* Slider.module.css */

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slideImg {
  width: 150px;
  height: 150px;
}

.slideCaption {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.activeSlide {
  transform: scale(1);
  transition: transform 0.5s ease;
}

.inactiveSlide {
  transform: scale(0.8);
  transition: transform 0.5s ease;
}
