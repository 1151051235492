.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 95%;
  margin: 0 auto;
  max-width: 1550px;
  position: relative;
}

.logo {
  font-weight: bold;
  color: #606060;
  font-size: 26px;
  cursor: pointer;
}

.logoWrap {
  display: flex;
  align-items: center;
}

.menuToggle {
  display: none;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 50px;
  cursor: pointer;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.show {
  display: flex;
  flex-direction: column;
}

.link {
  color: white;
  text-decoration: none;
  margin: 0 22px;
  font-size: 20px;
  font-weight: bold;
}

.link:hover {
  transform: scale(1.1);
}

.actionButtons {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .nav {
    display: none;
  }
  .menuToggle {
    display: block;
  }
  .logo {
    margin-left: 10px;
  }
  .show {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    display: none;
    margin-top: 60px;
    padding-left: 20px;
  }
  .show .link {
    margin: 20px 0;
    font-size: 24px;
  }
  .show {
    display: flex;
    animation: fadeIn 0.3s ease-out;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 650px) {
  .logo {
    display: none;
  }
}
