.inputField {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  font-size: 14px;
  outline: none;
  border-radius: 12px;
  background: #f2f2f2;
}

.inputFieldMultiLine {
  height: 150px;
  max-width: 100%;
  min-width: 100%;
}

.inputField:focus {
  border-color: #80bdff; /* Focus border color, adjust as needed */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Bootstrap-like focus shadow */
}

.inputField::placeholder {
  color: #bfbfbf; /* Light grey placeholder text */
  font-weight: 500;
}

.inputField:disabled {
  background: #e6e6e6; /* Light grey background for disabled inputs */
  cursor: not-allowed; /* Make cursor non-hoverable */
}

.label {
  display: block;
  margin: 5px 0 5px 0;
  color: #000;
  font-size: 15px;
  font-weight: 700;
}

.error {
  color: #ff4d4d;
  font-size: 11px;
  margin-top: -5px;
  margin-left: 5px;
}
