.itemDetails {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.itemDetailsHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.itemDetailsHeader h1 {
  margin: 0;
  font-size: 26px;
  flex-grow: 1;
}

.itemDetailsHeader svg {
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.itemDetailsHeader path {
  fill: #000;
}
