.listItem {
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: #d9d9d9;
  cursor: pointer;
}

.primaryLabel {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  word-wrap: anywhere;
}

.secondaryLabel {
  color: #606060;
  margin-top: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.selected {
  background: #345333;
}

.selected .primaryLabel {
  color: #fff;
}

.selected .secondaryLabel {
  color: #fff;
}

.iconWrap svg path {
  stroke: #000;
}

.selected .iconWrap svg path {
  stroke: #fff;
}

.selected .tertiaryLabel span {
  color: #fff;
}
