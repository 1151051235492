.featureItem {
  width: calc(33.333% - 40px);
  margin-bottom: 20px;
  align-items: flex-start;
}

.iconContainer {
  border: 6px solid #345333;
  height: 80px;
  width: 80px;
  border-radius: 30px;
  background-color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer svg {
  width: 36px !important;
  height: 36px !important;
  fill: #8cc057 !important;
  color: #8cc057 !important;
  margin-bottom: -1px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .featureItem {
    width: calc(50% - 40px);
  }
}

@media screen and (max-width: 600px) {
  .featureItem {
    width: calc(100% - 40px);
  }
}
