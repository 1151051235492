.inputField {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  font-size: 14px;
  outline: none;
  border-radius: 12px;
  background: #f2f2f2;
  -webkit-appearance: none; /* Removes default styling for dropdowns on WebKit browsers */
  -moz-appearance: none; /* Removes default styling for dropdowns on Firefox */
  appearance: none; /* Standard way to remove default arrow in modern browsers */
  font-family: "Montserrat", sans-serif;
}

.inputField:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.inputField::placeholder {
  color: #bfbfbf;
  font-weight: 500;
}

.inputField:disabled {
  background: #e6e6e6;
  cursor: not-allowed;
}

.label {
  display: block;
  margin: 5px 0 5px 0;
  color: #000;
  font-size: 15px;
  font-weight: 700;
}

.error {
  color: #ff4d4d;
  font-size: 11px;
  margin-top: -5px;
  margin-left: 5px;
}

/* Custom arrow for dropdown, using a background image */
.inputField {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg width='12px' height='12px' viewBox='0 0 4 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M2 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 40%;
  background-size: 12px 12px;
}
