.infoSection {
  background: linear-gradient(160deg, #84d334 0%, #42671b 100%);
  border-radius: 50px;
  padding: 40px;
  color: #ffffff;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 0;
}

.content {
  font-size: 18px;
  font-weight: 600;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .infoSection {
    padding: 20px;
  }
  .title {
    font-size: 20px;
  }
  .content {
    font-size: 14px;
  }
}
