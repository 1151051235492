.listItem {
  margin-bottom: 20px;
}

.folderListWrap {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  flex-wrap: wrap;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title p {
  margin: 0;
  font-weight: bold;
  color: #345333;
  font-size: 18px;
  margin-right: 4px;
}
.folderItem {
  display: inline-flex;
  align-items: center;
  background-color: #e9e9e9;
  padding: 12px 40px 12px 20px;
  margin-bottom: 16px;
  border-radius: 10px;
  margin-right: 16px;
  cursor: pointer;
}

.folderItem:hover {
  background-color: #dcdcdc;
  transform: scale(1.01);
}

.folderItem h6 {
  margin: 0;
  color: #404040;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
  margin-left: 8px;
}
