.notfoundWrap {
  background-color: #000;
  min-height: 87vh;
  color: #fff !important;
  padding: 20px;
}

.aboutHeader {
  width: 80%;
  margin: 100px auto;
  text-align: center;
}

.aboutHeaderContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutHeaderContent h1 {
  font-size: 32px;
  margin: 10px 0 0;
}

.aboutHeaderContent div p {
  font-size: 16px;
  margin: 0 0 20px;
  font-weight: 600;
}
