@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap");

html,
body,
p,
span,
a,
button {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  /* background: url("../../public/images/backgrounds/background3.svg") no-repeat; */
  background-size: 100vw auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.button {
  padding: 13px 20px;
  font-size: 14px;
  background-color: #0f4c75;
  display: inline-block;
  color: #fff;
  border-radius: 15px;
  border: none;
}

.button p {
  margin: 0;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
