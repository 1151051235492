.itemList {
  padding: 20px;
  border-right: 1px solid #bcbcbc;
  width: 350px;
  background-color: #ececec;
  color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.title {
  color: #000;
  font-size: 22px;
  margin-bottom: 20px;
}

.list {
  flex-grow: 1;
  overflow: auto;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 50px;
}

.noItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 250px;
  margin: 50px auto;
}

.noItems p {
  font-size: 16px;
  color: #000;
  text-align: center;
}
