.serverListItem {
  color: #606060;

  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.headerTitle {
  color: #333;
}

.icon {
  cursor: pointer;
}

.card {
  background: #f7f7f7;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 16px;
}

.cardTitle {
  margin: 0 0 20px 0;
  font-size: 20px;
  color: #333;
}

.cardContent {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cardContent h2 {
  margin: 0;
  font-size: 18px;
  margin-right: 5px;
  color: #333;
  flex-grow: 1;
}

.cardContent p {
  margin: 0;
  font-size: 18px;
  color: #333;
  font-weight: 600;
  text-transform: capitalize;
}

.cardButtonWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cardButton {
  border: none;
  color: white;
  padding: 13px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 12px;
}

.cardButtonStart {
  background: #4caf50;
}

.cardButtonStop {
  background: #ff4d4d;
}

.serverDetailsWrap {
  background-color: #363636;
  height: 45px;
  width: 100%;
  border-radius: 10px;
  margin-top: -10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.serverIP {
  color: #fff;
  font-size: 16px;
  flex-grow: 1;
  margin: 0 0 0 10px;
}

.clickToCopy {
  font-size: 12px;
  color: #606060;
  text-align: right;
  margin: 0 10px 0 0;
}

.serverCard {
  display: flex;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 16px;
}

.leftWrap {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.button {
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
}

.stopButton {
  background-color: #ff4d4d;
  color: white;
}

.restartButton {
  background-color: #4caf50;
  color: white;
}

.modalHeader {
  margin: 10px 0 30px 0;
}

.modalTitle {
  margin: 0;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.modalBody {
  margin-bottom: 20px;
}

.termsOfService {
  font-size: 11px;
  color: #444;
  margin-top: 20px;
  margin-bottom: -10px;
  text-align: center;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
}

.domain {
  color: #707070;
  margin-left: 6px;
  margin-bottom: 20px;
  font-size: 13px;
}

.generalError {
  color: #ff4d4d;
  font-size: 11px;
  text-align: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.serverTabs {
  height: 100%;
}

.tabList {
  border-bottom: 2px solid #ccc;
  padding-left: 0;
  display: flex;
  margin: 0;
}

.tab {
  color: #646464;
  font-weight: 600;
  text-align: center;
  font-size: 18px;

  display: inline-block;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 2px 12px 6px 12px;
  margin: 0 4px;
  cursor: pointer;
  outline: none;
}

.tabSelected {
  font-weight: 700;
  color: #345333;
  border-bottom: 4px solid #345333;
}

.tabSelected::after {
  outline: none;
}

.tabPanel {
  border-top: none;
  padding: 10px;
  background-color: #fff;
}
