/* UserList.module.css */
.container {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
}

.playersAddModal h1 {
  margin: 8px 0 24px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
